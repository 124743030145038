import { useEffect } from "react"

import { navigate } from "~/gatsby/navigate"
import { getRedirectLanguage } from "~/utils/locales"

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage()

    navigate(`/${urlLang}/`, { replace: true })
  }, [])

  return null
}

export default IndexPage
